import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Divider from "../components/Divider"
import HorizontalSlider from "../components/HorizontalSlider"
import FadeIn from "../components/FadeIn"
import BeforeAndAfter from "../components/BeforeAndAfter"
import SingleImage from "../components/SingleImage"

const PhotoGalleryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      eyeliner_1_before: file(
        relativePath: { eq: "before-and-after/eyeliner-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_1_after: file(
        relativePath: { eq: "before-and-after/eyeliner-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_2_before: file(
        relativePath: { eq: "before-and-after/eyeliner-2-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_2_after: file(
        relativePath: { eq: "before-and-after/eyeliner-2-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_3_before: file(
        relativePath: { eq: "before-and-after/eyeliner-3-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_3_after: file(
        relativePath: { eq: "before-and-after/eyeliner-3-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_4_before: file(
        relativePath: { eq: "before-and-after/eyeliner-4-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_4_after: file(
        relativePath: { eq: "before-and-after/eyeliner-4-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_5_before: file(
        relativePath: { eq: "before-and-after/eyeliner-5-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyeliner_5_after: file(
        relativePath: { eq: "before-and-after/eyeliner-5-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      eyebrows_1_before: file(
        relativePath: { eq: "before-and-after/eyebrows-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_1_after: file(
        relativePath: { eq: "before-and-after/eyebrows-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_2_before: file(
        relativePath: { eq: "before-and-after/eyebrows-2-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_2_after: file(
        relativePath: { eq: "before-and-after/eyebrows-2-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_3_before: file(
        relativePath: { eq: "before-and-after/eyebrows-3-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_3_after: file(
        relativePath: { eq: "before-and-after/eyebrows-3-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_4_before: file(
        relativePath: { eq: "before-and-after/eyebrows-4-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_4_after: file(
        relativePath: { eq: "before-and-after/eyebrows-4-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_4_before: file(
        relativePath: { eq: "before-and-after/eyebrows-4-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_4_after: file(
        relativePath: { eq: "before-and-after/eyebrows-4-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_5_before: file(
        relativePath: { eq: "before-and-after/eyebrows-5-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_5_after: file(
        relativePath: { eq: "before-and-after/eyebrows-5-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_6_before: file(
        relativePath: { eq: "before-and-after/eyebrows-6-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      eyebrows_6_after: file(
        relativePath: { eq: "before-and-after/eyebrows-6-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      lips_1_before: file(
        relativePath: { eq: "before-and-after/lips-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_1_after: file(
        relativePath: { eq: "before-and-after/lips-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_2_before: file(
        relativePath: { eq: "before-and-after/lips-2-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_2_after: file(
        relativePath: { eq: "before-and-after/lips-2-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_3_before: file(
        relativePath: { eq: "before-and-after/lips-3-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_3_after: file(
        relativePath: { eq: "before-and-after/lips-3-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_4_before: file(
        relativePath: { eq: "before-and-after/lips-4-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_4_after: file(
        relativePath: { eq: "before-and-after/lips-4-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_5_before: file(
        relativePath: { eq: "before-and-after/lips-5-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lips_5_after: file(
        relativePath: { eq: "before-and-after/lips-5-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      areola_1_before: file(
        relativePath: { eq: "before-and-after/areola-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_1_after: file(
        relativePath: { eq: "before-and-after/areola-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_2_before: file(
        relativePath: { eq: "before-and-after/areola-2-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_2_after: file(
        relativePath: { eq: "before-and-after/areola-2-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_3_before: file(
        relativePath: { eq: "before-and-after/areola-3-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_3_after: file(
        relativePath: { eq: "before-and-after/areola-3-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_4_before: file(
        relativePath: { eq: "before-and-after/areola-4-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_4_after: file(
        relativePath: { eq: "before-and-after/areola-4-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_5_before: file(
        relativePath: { eq: "before-and-after/areola-5-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_5_after: file(
        relativePath: { eq: "before-and-after/areola-5-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_6_before: file(
        relativePath: { eq: "before-and-after/areola-6-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      areola_6_after: file(
        relativePath: { eq: "before-and-after/areola-6-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_1_before: file(
        relativePath: { eq: "before-and-after/decorative-areola-1-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_1_after: file(
        relativePath: { eq: "before-and-after/decorative-areola-1-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_2_before: file(
        relativePath: { eq: "before-and-after/decorative-areola-2-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_2_after: file(
        relativePath: { eq: "before-and-after/decorative-areola-2-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_3_before: file(
        relativePath: { eq: "before-and-after/decorative-areola-3-before.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_3_after: file(
        relativePath: { eq: "before-and-after/decorative-areola-3-after.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_4: file(
        relativePath: { eq: "single-images/decorative-areola-humming-bird.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_5: file(
        relativePath: { eq: "single-images/decorative-areola-flowers.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_areola_6: file(
        relativePath: { eq: "single-images/decorative-areola-flowers-2.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_1: file(
        relativePath: { eq: "single-images/denovo-tattoo-flowers.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_2: file(
        relativePath: { eq: "single-images/denovo-tattoo-octopus.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_3: file(
        relativePath: { eq: "single-images/denovo-tattoo-skeleton.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_4: file(
        relativePath: { eq: "single-images/denovo-tattoo-seal.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_5: file(
        relativePath: { eq: "single-images/denovo-tattoo-unravelling-man.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_6: file(
        relativePath: {
          eq: "single-images/denovo-tattoo-where-the-wild-things-are.jpg"
        }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_7: file(
        relativePath: { eq: "single-images/denovo-tattoo-ear.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_8: file(
        relativePath: { eq: "single-images/denovo-tattoo-tinkerbell.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      decorative_9: file(
        relativePath: { eq: "single-images/denovo-tattoo-peanuts.jpg" }
      ) {
        childImageSharp {
          thumb: fluid(maxWidth: 480, quality: 75) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 960, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Photo Gallery" />
      <section id="intro">
        <FadeIn className="lede" duration={0.5}>
          <p>
            We want you to look and feel beautiful, every day. Because client
            satisfaction is our top priority, we tailor each procedure to fit
            your features and aesthetic preferences so you look your best.
          </p>
        </FadeIn>
      </section>
      <Divider style={{ paddingBottom: "2rem" }} />

      <HorizontalSlider title="Eyeliner" id="eyeliner">
        <BeforeAndAfter
          beforeImg={data.eyeliner_1_before.childImageSharp}
          beforeAlt="Eyeliner permanent cosmetics, before photo"
          afterImg={data.eyeliner_1_after.childImageSharp}
          afterAlt="Eyeliner permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyeliner_2_before.childImageSharp}
          beforeAlt="Eyeliner permanent cosmetics, before photo"
          afterImg={data.eyeliner_2_after.childImageSharp}
          afterAlt="Eyeliner permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyeliner_5_before.childImageSharp}
          beforeAlt="Eyeliner permanent cosmetics, before photo"
          afterImg={data.eyeliner_5_after.childImageSharp}
          afterAlt="Eyeliner permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyeliner_4_before.childImageSharp}
          beforeAlt="Eyeliner permanent cosmetics, before photo"
          afterImg={data.eyeliner_4_after.childImageSharp}
          afterAlt="Eyeliner permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyeliner_3_before.childImageSharp}
          beforeAlt="Eyeliner permanent cosmetics, before photo"
          afterImg={data.eyeliner_3_after.childImageSharp}
          afterAlt="Eyeliner permanent cosmetics, after photo"
        />
      </HorizontalSlider>

      <HorizontalSlider title="Eyebrows" id="eyebrows">
        <BeforeAndAfter
          beforeImg={data.eyebrows_1_before.childImageSharp}
          beforeAlt="Eyebrows permanent cosmetics, before photo"
          afterImg={data.eyebrows_1_after.childImageSharp}
          afterAlt="Eyebrows permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyebrows_2_before.childImageSharp}
          beforeAlt="Eyebrows permanent cosmetics, before photo"
          afterImg={data.eyebrows_2_after.childImageSharp}
          afterAlt="Eyebrows permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyebrows_3_before.childImageSharp}
          beforeAlt="Eyebrows permanent cosmetics, before photo"
          afterImg={data.eyebrows_3_after.childImageSharp}
          afterAlt="Eyebrows permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyebrows_4_before.childImageSharp}
          beforeAlt="Eyebrows permanent cosmetics, before photo"
          afterImg={data.eyebrows_4_after.childImageSharp}
          afterAlt="Eyebrows permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyebrows_5_before.childImageSharp}
          beforeAlt="Eyebrows permanent cosmetics, before photo"
          afterImg={data.eyebrows_5_after.childImageSharp}
          afterAlt="Eyebrows permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.eyebrows_6_before.childImageSharp}
          beforeAlt="Eyebrows permanent cosmetics, before photo"
          afterImg={data.eyebrows_6_after.childImageSharp}
          afterAlt="Eyebrows permanent cosmetics, after photo"
        />
      </HorizontalSlider>

      <HorizontalSlider title="Lips" id="lips">
        <BeforeAndAfter
          beforeImg={data.lips_4_before.childImageSharp}
          beforeAlt="Lips permanent cosmetics, before photo"
          afterImg={data.lips_4_after.childImageSharp}
          afterAlt="Lips permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.lips_5_before.childImageSharp}
          beforeAlt="Lips permanent cosmetics, before photo"
          afterImg={data.lips_5_after.childImageSharp}
          afterAlt="Lips permanent cosmetics, after photo"
        />

        <BeforeAndAfter
          beforeImg={data.lips_2_before.childImageSharp}
          beforeAlt="Lips permanent cosmetics, before photo"
          afterImg={data.lips_2_after.childImageSharp}
          afterAlt="Lips permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.lips_3_before.childImageSharp}
          beforeAlt="Lips permanent cosmetics, before photo"
          afterImg={data.lips_3_after.childImageSharp}
          afterAlt="Lips permanent cosmetics, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.lips_1_before.childImageSharp}
          beforeAlt="Lips permanent cosmetics, before photo"
          afterImg={data.lips_1_after.childImageSharp}
          afterAlt="Lips permanent cosmetics, after photo"
        />
      </HorizontalSlider>

      <HorizontalSlider title="Repigmentation" id="repigmentation">
        <BeforeAndAfter
          beforeImg={data.areola_5_before.childImageSharp}
          beforeAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.areola_5_after.childImageSharp}
          afterAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.areola_6_before.childImageSharp}
          beforeAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.areola_6_after.childImageSharp}
          afterAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.areola_4_before.childImageSharp}
          beforeAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.areola_4_after.childImageSharp}
          afterAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.areola_1_before.childImageSharp}
          beforeAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.areola_1_after.childImageSharp}
          afterAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.areola_2_before.childImageSharp}
          beforeAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.areola_2_after.childImageSharp}
          afterAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
        <BeforeAndAfter
          beforeImg={data.areola_3_before.childImageSharp}
          beforeAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.areola_3_after.childImageSharp}
          afterAlt="Areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
      </HorizontalSlider>
      <HorizontalSlider
        title="Decorative Repigmentation"
        id="decorative-repigmentation"
      >
        <SingleImage
          image={data.decorative_areola_5.childImageSharp}
          alt="Decorative areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery"
        />
        <BeforeAndAfter
          beforeImg={data.decorative_areola_3_before.childImageSharp}
          beforeAlt="Decorative aeola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.decorative_areola_3_after.childImageSharp}
          afterAlt="Decorative areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
        <SingleImage
          image={data.decorative_areola_6.childImageSharp}
          alt="Decorative areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery"
        />
        <BeforeAndAfter
          beforeImg={data.decorative_areola_2_before.childImageSharp}
          beforeAlt="Decorative aeola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.decorative_areola_2_after.childImageSharp}
          afterAlt="Decorative areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
        <SingleImage
          image={data.decorative_areola_4.childImageSharp}
          alt="Decorative areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery"
        />
        <BeforeAndAfter
          beforeImg={data.decorative_areola_1_before.childImageSharp}
          beforeAlt="Decorative areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, before photo"
          afterImg={data.decorative_areola_1_after.childImageSharp}
          afterAlt="Decorative areola repigmentation permanent cosmetics post-breast cancer mastectomy reconstrutive surgery, after photo"
        />
      </HorizontalSlider>
      <HorizontalSlider title="Decorative Tattooing" id="decorative">
        <SingleImage
          image={data.decorative_1.childImageSharp}
          alt="De Novo decorative tattoo of flowers"
        />
        <SingleImage
          image={data.decorative_2.childImageSharp}
          alt="De Novo decorative tattoo of an octopus"
        />
        <SingleImage
          image={data.decorative_3.childImageSharp}
          alt="De Novo decorative tattoo of a dancing skeleton"
        />
        <SingleImage
          image={data.decorative_4.childImageSharp}
          alt="De Novo decorative tattoo of a seal"
        />
        <SingleImage
          image={data.decorative_5.childImageSharp}
          alt="De Novo decorative tattoo of an artistic illustration of an unravelling man"
        />
        <SingleImage
          image={data.decorative_6.childImageSharp}
          alt="De Novo decorative tattoo of Max from Where the Wild Things Are"
        />
      </HorizontalSlider>
      <Divider />
    </Layout>
  )
}

export default PhotoGalleryPage
