import React, { useEffect, useState, useRef } from "react"
import { Portal } from "react-portal"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

import "./Lightbox.scss"

const Lightbox = ({ children, className }) => {
  const lightboxRef = useRef(null)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const closeDuration = 250

  const handleClose = () => {
    lightboxRef.current.style.cssText = `animation: fade-out ${closeDuration}ms ease; opacity: 0`
    setTimeout(() => {
      setOpen(false)
    }, closeDuration)
  }

  const escFunction = e => {
    if (e.keyCode === 27 && open) {
      handleClose()
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)
    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  })

  return (
    <>
      <div
        className={`lightbox-link`}
        onClick={handleOpen}
        onKeyPress={handleOpen}
        role="button"
        tabIndex={-1}
        aria-label="open lightbox"
      />
      {open && (
        <Portal
          node={
            typeof document !== "undefined" &&
            document.getElementById("___gatsby")
          }
        >
          <Helmet htmlAttributes={{ class: "lightbox-open" }} />
          <div className={`lightbox ${className || ""}`} ref={lightboxRef}>
            <div className="lightbox-wrapper">
              <div className="wrapper-inner">
                <div
                  className="lightbox-bg"
                  onClick={handleClose}
                  onKeyPress={handleClose}
                  role="button"
                  tabIndex={-1}
                  aria-label="close lightbox"
                ></div>
                <div className="lightbox-container">
                  <div
                    className="close-button"
                    onClick={handleClose}
                    onKeyPress={handleClose}
                    role="button"
                    tabIndex={-1}
                    aria-label="close lightbox"
                  />
                  <div className="lightbox-content">{children}</div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

Lightbox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Lightbox
