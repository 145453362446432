import React from 'react'
import Img from 'gatsby-image'

import './BeforeAndAfter.scss'

import windowSize from '../hooks/windowSize'

import Lightbox from './Lightbox'

const BeforeAndAfter = ({ beforeImg, beforeAlt, afterImg, afterAlt }) => {
  const { innerWidth } = windowSize()

  return (
    <div className="before-and-after">
      <Img fluid={beforeImg.thumb} alt={beforeAlt} />
      <Img fluid={afterImg.thumb} alt={afterAlt} />

      {innerWidth > 540 && (
        <Lightbox className="b-a-lightbox">
          <Img fluid={beforeImg.full} alt={beforeAlt} />
          <Img fluid={afterImg.full} alt={afterAlt} />
        </Lightbox>
      )}
    </div>
  )
}

export default BeforeAndAfter
