import React from "react";
import Img from "gatsby-image";

import "./SingleImage.scss";

import windowSize from "../hooks/windowSize";

import Lightbox from "./Lightbox";

const SingleImage = ({ image, alt }) => {
  const { innerWidth } = windowSize();

  return (
    <div className="single-image">
      <Img fluid={image.thumb} alt={alt} />
      {innerWidth > 540 && (
        <Lightbox className="single-image-lightbox">
          <Img fluid={image.full} alt={alt} />
        </Lightbox>
      )}
    </div>
  );
};

export default SingleImage;
