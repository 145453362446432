import React, { useRef, useEffect, useState } from "react"
import throttle from "lodash/throttle"
import smoothscroll from "smoothscroll-polyfill"

import "./HorizontalSlider.scss"

import FadeIn from "./FadeIn"

const HorizontalSlider = ({ children, title, id }) => {
  const contentRef = useRef(null)
  const sliderRef = useRef(null)
  const scrollWidthRef = useRef(null)
  const [scrollPos, setScrollPos] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)
  const [scrollWidth, setScrollWidth] = useState(0)

  const setWidths = () => {
    if (sliderRef.current && contentRef.current && scrollWidthRef.current) {
      setContainerWidth(
        Math.round(sliderRef.current.getBoundingClientRect().width)
      )
      setContentWidth(
        Math.round(contentRef.current.getBoundingClientRect().width)
      )
      setScrollWidth(
        Math.round(scrollWidthRef.current.getBoundingClientRect().width)
      )
    }
  }

  useEffect(() => {
    setWidths()
  })

  const handleSetWidths = throttle(setWidths, 100)

  useEffect(() => {
    window.addEventListener("resize", handleSetWidths)
    return () => {
      window.addEventListener("resize", handleSetWidths)
    }
  }, [handleSetWidths])

  const scrollEffect = () => {
    setScrollPos(contentRef.current.getBoundingClientRect().x)
  }
  const handleScroll = throttle(scrollEffect, 50)
  useEffect(() => {
    const slider = sliderRef.current
    slider.addEventListener("scroll", handleScroll)
    return () => {
      slider.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  if (typeof window !== "undefined") {
    smoothscroll.polyfill()
  }

  const handleScrollBack = () => {
    sliderRef.current.scrollTo({
      top: 0,
      left: Math.ceil(-scrollPos / scrollWidth - 1) * scrollWidth,
      behavior: "smooth"
    })
    console.log(`
    scrollPos: ${scrollPos}
    scrollWidth: ${scrollWidth}
    contentWidth: ${contentWidth}`)
  }
  const handleScrollForward = () => {
    sliderRef.current.scrollTo({
      top: 0,
      left: Math.floor(-scrollPos / scrollWidth + 1) * scrollWidth,
      behavior: "smooth"
    })
    console.log(`
    scrollPos: ${scrollPos}
    scrollWidth: ${scrollWidth}    
    contentWidth: ${contentWidth}`)
  }

  return (
    <>
      <FadeIn
        id={id ? id : ""}
        className="slider-section"
        elementType="section"
      >
        <div className="slider-title">{title && <h2>{title}</h2>}</div>
        {containerWidth < contentWidth && (
          <nav className="slider-nav">
            <span
              className="scroll-back"
              onClick={handleScrollBack}
              onKeyPress={handleScrollBack}
              role="button"
              aria-label="slide back"
              tabIndex={-1}
            >
              <span className={`arrow ${scrollPos >= -1 ? "disabled" : ""}`} />
            </span>
            <span
              className="scroll-forward"
              onClick={handleScrollForward}
              onKeyPress={handleScrollForward}
              role="button"
              aria-label="slide forward"
              tabIndex={-1}
            >
              <span
                className={`arrow ${
                  containerWidth - scrollPos >= contentWidth - 1
                    ? "disabled"
                    : ""
                }`}
              />
            </span>
          </nav>
        )}
        <div className="scroll-width" ref={scrollWidthRef} />
        <div className="slider-wrap">
          <div className="horizontal-slider" ref={sliderRef}>
            <div className="content-wrap" ref={contentRef}>
              {children}
            </div>
          </div>
        </div>
      </FadeIn>
    </>
  )
}

export default HorizontalSlider
